import React from "react"
// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import axios from 'axios'
// import { CSVLink } from "react-csv";
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';


function Region() {

  


    // // Today

    // let idSum = 0;
    // for (let i = 0; items && i < items.length; i++) {
    //     idSum += items[i].today;
    // }

    // // yesterday

    // let idSumTodayQty = 0;
    // for (let i = 0; items && i < items.length; i++) {
    //     idSumTodayQty += items[i].todayQty;
    // }
    // // monthly

    // let idSumYes = 0;
    // for (let i = 0; items && i < items.length; i++) {
    //     idSumYes += items[i].yesterday;
    // }
    // // monthly

    // let idSumYesQty = 0;
    // for (let i = 0; items && i < items.length; i++) {
    //     idSumYesQty += items[i].yesterdayQty;
    // }


    // //previous

    // let idSumMon = 0;
    // for (let i = 0; items && i < items.length; i++) {
    //     idSumMon += items[i].monthly;
    // }
    // //previous

    // let idSumMonQty = 0;
    // for (let i = 0; items && i < items.length; i++) {
    //     idSumMonQty += items[i].monthlyQty;
    // }
    // //previous

    // let idSumpreMon = 0;
    // for (let i = 0; items && i < items.length; i++) {
    //     idSumpreMon += items[i].previous;
    // }
    // //previous

    // let idSumpreMonQty = 0;
    // for (let i = 0; items && i < items.length; i++) {
    //     idSumpreMonQty += items[i].previousQty;
    // }
    return (
        <>
            <h4>Region Summary</h4>
        </>
    )
}

export default Region